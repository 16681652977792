<template>
  <div class="mentor-info">
    <div class="credentials">
      <img v-if="avatar_url" :src="avatar_url" alt="Аватар" class="avatar">
      <AvatarPlaceholder v-else small :first-name="first_name" :last-name="last_name" :avatar-color="avatar_color" class="avatar" />
      <div class="info">
        <div class="name">{{ fullName }}</div>
        <div class="sub">{{ sub }}</div>
      </div>
    </div>
    <BaseButton v-if="viewBtn && !user?.is_mentor" class="btn" stretch :to="{ name: 'mentor-profile', params: { id }}">Посмотреть профиль</BaseButton>
    <div class="desc" v-if="desc">{{ about ?? 'Описание отсутствует' }}</div>
  </div>
</template>

<script setup>
import AvatarPlaceholder from '@/components/common/AvatarPlaceholder'
import BaseButton from '@/components/common/BaseButton'

const props = defineProps({
  id: Number,
  avatar_url: String,
  avatar_color: String,
  first_name: String,
  last_name: String,
  sub: String,
  about: String,
  desc: {
    type: Boolean,
    default: false
  },
  viewBtn: Boolean
})

const user = inject('user')

const fullName = computed(() => `${props.first_name} ${props.last_name}`)
</script>

<style scoped lang="scss">
.mentor-info {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .credentials {
    display: flex;
    align-items: center;
    & .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }
    & .info {
      & .name {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      & .sub {
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: var(--color-elements-secondary);
      }
    }
  }

  .desc {
    padding-top: 16px;
    border-top: 1px solid var(--color-separator);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-elements-secondary);
    white-space: pre-line;
    max-height: 309px;
    overflow: hidden;
  }
}
</style>
